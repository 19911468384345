<template>
  <div class="review-container">
    <my-nav-bar
      title="拣货复核"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-cell
        v-for="(line, index) in lines"
        :key="index"
        style="height: 50px"
        center
        :title="line.name"
        is-link
        :value="line.orders_count ? line.orders_count + '张订单待复核' : ''"
        @click="toListView(line)"
      />
    </div>
  </div>
</template>

<script>
import lineApi from '@/api/line'
import myNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'ReviewIndex',
  components: { myNavBar },
  data() {
    return {
      lines: null
    }
  },
  created() {
    this.getLines()
  },
  methods: {
    getLines() {
      lineApi.review().then(res => {
        this.lines = res.data
      })
    },
    toListView(line) {
      this.$router.push({ path: '/review-list', query: { line_name: line.name, line_id: line.id, mode: line.mode }})
    }
  }
}
</script>

<style scoped>

</style>
