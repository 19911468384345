var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "review-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "拣货复核",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, _vm._l(_vm.lines, function (line, index) {
    return _c('van-cell', {
      key: index,
      staticStyle: {
        "height": "50px"
      },
      attrs: {
        "center": "",
        "title": line.name,
        "is-link": "",
        "value": line.orders_count ? line.orders_count + '张订单待复核' : ''
      },
      on: {
        "click": function click($event) {
          return _vm.toListView(line);
        }
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }